import styled from 'styled-components';
export const Footer = styled.footer`
	width: 100%;
	border-top: 1px solid #fff;
	padding: 3rem;
`;

export const Wrap = styled.div`
	background: #132643;
	padding: 20px 5%;
`;

export const Logo = styled.img`
	display: block;
	max-width: 120px;
	width: 100%;
	height: 60px;
`;
