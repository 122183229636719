import styled from 'styled-components';

export const Card = styled.div`
	line-height: 1.5;
	img {
		width: 100%;
		height: auto;
		max-width: 70px;
	}
	h4 {
		font-size: 1.5rem;
		font-weight: 900;
		padding-bottom: 1rem;
		margin-bottom: 1.5rem;
		border-bottom: 1px solid #ff0000;
	}
`;
