import DownArrow from 'common/downArrow';
import { Card } from './styles';
import { Content, Paragraph, Grid } from 'common/styles';
const Statement = () => {
	return (
		<Content background="#132643">
			<div>
				<DownArrow />
				<Paragraph
					$color="#fff"
					$maxWidth="80%"
					$margin="3rem auto"
					$center>
					Discover how our unique approach drives and protects your business.{' '}
					<span className="block">Explore our coverage options!</span>
				</Paragraph>
				<Grid $columns={4}>
					<Card className="cardSeparatorFour">
						<img
							src="/img/statement/civil.png"
							alt="Civil liability"
						/>
						<h3>Civil liability</h3>
					</Card>
					<Card className="cardSeparatorFour">
						<img
							src="/img/statement/damage.png"
							alt="damage"
						/>
						<h3>Damage</h3>
					</Card>
					<Card className="cardSeparatorFour">
						<img
							src="/img/statement/life.png"
							alt="life"
						/>
						<h3>Life</h3>
					</Card>
					<Card className="cardSeparatorFour">
						<img
							src="/img/statement/personal.png"
							alt="personal accidents"
						/>
						<h3>Personal accidents</h3>
					</Card>
				</Grid>
			</div>
		</Content>
	);
};

export default Statement;
