import { useContext, useState } from 'react';
import { Wrap, Nav, Menu, MenuItem, Overlay, Icons } from './styles';
import { GlobalsContext } from 'context/globals';
import Hamburger from '../hamburger';
import { Link } from 'react-router-dom';

const Navigation = () => {
	const { state, dispatch } = useContext(GlobalsContext);
	const toggleMenu = () => {
		dispatch({
			statusMenu: state.statusMenu ? false : true,
		});
	};
	const handleScroll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
		event.preventDefault();
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
		toggleMenu();
	};
	return (
		<Wrap>
			<Nav className={`${state.statusMenu ? 'open' : ''}`}>
				<Menu>
					<MenuItem className="mainLink">
						<Link
							to="#ourcompany"
							onClick={e => handleScroll(e, 'ourcompany')}>
							<span>{'>'}</span>Our company
						</Link>
					</MenuItem>
					<MenuItem className="mainLink">
						<Link
							to="#highlights"
							onClick={e => handleScroll(e, 'highlights')}>
							<span>{'>'}</span>Highlights
						</Link>
					</MenuItem>
					<MenuItem className="mainLink">
						<Link
							to="#industries"
							onClick={e => handleScroll(e, 'industries')}>
							<span>{'>'}</span>Industries
						</Link>
					</MenuItem>
					<MenuItem className="mainLink">
						<Link
							to="#partner"
							onClick={e => handleScroll(e, 'partner')}>
							<span>{'>'}</span>Partner With Us
						</Link>
					</MenuItem>
					<MenuItem className="mainLink">
						<Link
							to="#contact"
							onClick={e => handleScroll(e, 'contact')}>
							<span>{'>'}</span>Contact
						</Link>
					</MenuItem>
				</Menu>
			</Nav>
			<Overlay
				onClick={toggleMenu}
				className={`${state.statusMenu ? 'open' : ''}`}
			/>
			<Icons>
				<Hamburger />
			</Icons>
		</Wrap>
	);
};

export default Navigation;
