import { Wrap, HeroTitle } from './styles';

const Hero = () => {
	return (
		<Wrap>
			<HeroTitle>
				We protect <span>the future of your business</span> with reliable reinsurance solutions.
			</HeroTitle>
		</Wrap>
	);
};
export default Hero;
