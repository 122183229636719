import { Outlet } from 'react-router-dom';
import FooterComponent from './foter';
import HeaderComponent from './header';
const Layout = () => {
	return (
		<>
			<HeaderComponent />
			<Outlet />
			<FooterComponent />
		</>
	);
};
export default Layout;
