import { Routes, Route, Navigate } from 'react-router-dom';
import Home from 'pages/home';
import Layout from 'layout';
const Router = () => {
	return (
		<>
			<Routes>
				<Route element={<Layout />}>
					<Route
						path="/"
						element={<Home />}
					/>
					<Route
						path="*"
						element={<Navigate to="/" />}
					/>
				</Route>
			</Routes>
		</>
	);
};
export default Router;
