import { useState, useEffect } from 'react';
import { Wrap, Logo, HeaderCont } from './styles';
import Navigation from '../navigation';
import { Link } from 'react-router-dom';

const Header = () => {
	const [scrolled, setScrolled] = useState(false);
	const handleScroll = () => {
		console.log('scrolling');
		if (window.scrollY > 100) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<>
			<Wrap className={scrolled ? 'scrolled' : ''}>
				<HeaderCont>
					<Logo>
						<Link to="/">
							<img
								src={`/img/epsilon.png`}
								alt="Epsilon"
							/>
						</Link>
					</Logo>
					<Navigation />
				</HeaderCont>
			</Wrap>
		</>
	);
};

export default Header;
