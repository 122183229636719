import { Content, Title, Grid } from 'common/styles';
import { CardHighlight, NumberCard } from './styles';
const Highlights = () => {
	return (
		<>
			<Content
				background="#B3B3B3"
				$padding="4rem 5%"
				id="highlights">
				<div>
					<Title
						$color="#132643"
						$center
						$margin="0 0 3rem 0">
						Highlights
					</Title>
					<Grid $columns={2}>
						<CardHighlight $background="/img/img/highlights/h1.jpg">
							<div>
								<h4>Global Presence:</h4>
								<p>
									Our headquarters are in Barbados and we have operations in different countries
									throughout the world.
								</p>
							</div>
						</CardHighlight>
						<CardHighlight $background="/img/img/highlights/h2.jpg">
							<div>
								<h4>Multidisciplinary Team:</h4>
								<p>
									A diverse range of professionals and specialists in areas such as finance, sales,
									internal control, actuarial and technical, ensure comprehensive and expert
									management of our services.
								</p>
							</div>
						</CardHighlight>
						<CardHighlight $background="/img/img/highlights/h3.jpg">
							<div>
								<h4>Continuous Innovation:</h4>
								<p>
									We remain at the forefront of the industry, offering innovative solutions that are
									adapted to a constantly changing environment, and ensuring that your company is
									always one step ahead.
								</p>
							</div>
						</CardHighlight>
						<CardHighlight $background="/img/img/highlights/h4.jpg">
							<div>
								<h4>Strong Relationships:</h4>
								<p>
									We believe in cultivating strong, long-lasting relationships with a wide range of
									nationally recognized clients and commercial partners, both public and private.
								</p>
							</div>
						</CardHighlight>
					</Grid>
				</div>
			</Content>
			<Content
				background="#32425B"
				$padding="4rem 5%"
				id="industries">
				<div>
					<Title
						$center
						$margin="0 0 3rem 0">
						These are some highlights of our comprehensive coverage:
					</Title>
					<Grid $columns={2}>
						<NumberCard>
							<b>9 million</b>
							<p>PEOPLE</p>
						</NumberCard>
						<NumberCard>
							<b>3 million</b>
							<p>DAILY USERS OF PUBLIC TRANSPORTATION</p>
						</NumberCard>
						<NumberCard>
							<b>10 thousand</b>
							<p>LIVESTOCK</p>
						</NumberCard>
						<NumberCard>
							<b>22</b>
							<p>PORT SYSTEMS</p>
						</NumberCard>
						<NumberCard>
							<b>5 thousand</b>
							<p>MILES OF INFRASTRUCTURE</p>
						</NumberCard>
					</Grid>
				</div>
			</Content>
		</>
	);
};
export default Highlights;
