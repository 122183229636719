import styled from 'styled-components';

interface ITitle {
	$center?: boolean;
	$margin?: string;
	$color?: string;
}
export const Title = styled.h2<ITitle>`
	font-size: 2.8331rem;
	font-weight: 900;
	color: ${props => props.$color || '#fff'};
	margin: ${props => props.$margin || '0'};
	text-align: ${({ $center }) => ($center ? 'center' : 'left')};
`;

interface ISubtitle {
	$center?: boolean;
	$separator?: boolean;
	$margin?: string;
}
export const Subtitle = styled.h2<ISubtitle>`
	color: ${props => props.theme.textColor};
	display: flex;
	margin: ${props => (props.$margin ? props.$margin : '0')};
	justify-content: ${({ $center }) => ($center ? 'center' : 'left')};
	${props => {
		if (props.$separator) {
			return `
        border-bottom: 1px solid ${props.theme.separatorColor};
        padding-bottom: 10px;
      `;
		}
	}}
`;

interface IParagraph {
	$center?: boolean;
	$maxWidth?: string;
	$margin?: string;
	$color?: string;
	$litteSize?: boolean;
}
export const Paragraph = styled.p<IParagraph>`
	font-size: ${props => (props.$litteSize ? '1.325rem' : '1.5831rem')};
	font-weight: 500;
	line-height: 1.5;
	color: ${props => props.$color || '#fff'};
	margin: ${props => props.$margin || '0'};
	text-align: ${({ $center }) => ($center ? 'center' : 'left')};
	max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : '100%')};
	span {
		font-weight: 800;
		&.block {
			display: block;
		}
	}
`;
interface ITextTitle {
	$center?: boolean;
	$color?: string;
}
export const TexstTitle = styled.h3<ITextTitle>`
	font-size: 1.9169rem;
	font-weight: 800;
	margin-bottom: 1rem;
	text-align: ${({ $center }) => ($center ? 'center' : 'left')};
	color: ${props => props.$color || props.theme.textColor};
`;
