import { Wrap } from './styles';
const Contact = () => {
	return (
		<Wrap>
			<p>Contact us to start building a safer future:</p>
			<input
				type="text"
				placeholder="E-mail"
			/>
		</Wrap>
	);
};
export default Contact;
