import styled from 'styled-components';

export const Wrap = styled.section`
	width: 100%;
	height: 100vh;
	max-height: 700px;
	display: flex;
	align-items: center;
	padding: 0% 10%;
	background: url(/img/hero.jpg) no-repeat center center/cover;
	background-position: fixed;
`;

export const HeroTitle = styled.h1`
	max-width: 60%;
	font-size: 2.8331rem;
	color: #fff;
	font-weight: 600;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	span {
		font-weight: 900;
	}
	@media screen and (max-width: 768px) {
		max-width: 100%;
		text-align: center;
	}
	@media screen and (max-width: 600px) {
		font-size: 1.8rem;
	}
`;
