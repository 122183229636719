import { Content, Grid, Title } from 'common/styles';
import { Card } from './styles';
const Partner = () => {
	return (
		<Content background="#132643">
			<div>
				<Title
					$center
					$margin="2rem 0 0 0"
					$color="#FFF"
					id="partner">
					Partner with us
				</Title>
				<Grid
					$columns={3}
					$separator="6rem 0">
					<Card>
						<img
							src="/img/partner/regulations.png"
							alt="Regulations"
						/>
						<h4>Regulations</h4>
						<p>
							Our effort will always be aimed at offering you the best service and the highest
							quality. We operate in compliance with applicable regulations and standards, backed by
							external audits and a class 2 insurance license.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/innovation.png"
							alt="Innovation"
						/>
						<h4>Innovation</h4>
						<p>
							We constantly improve the services available to our insured clients and beneficiaries.
							We use technology to our advantage to perform advanced data analysis and thus offer
							reinsurance solutions adapted to the needs of the market. We guarantee accurate
							evaluations and optimal selection of risks.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/scope.png"
							alt="Scope"
						/>
						<h4>Scope</h4>
						<p>
							In the period from 2020 to 2023, Epsilon Insurance provided damage coverage for more
							than 300 million euros per year.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/strategic.png"
							alt="Strategic partnerships"
						/>
						<h4>Strategic partnerships</h4>
						<p>
							To expand our distribution network and have greater reach, we have strategic alliances
							with the most important insurance brokers and agencies.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/partners.png"
							alt="Partners and clients"
						/>
						<h4>Partners and clients</h4>
						<p>
							We believe in cultivating strong, long-lasting relationships with a wide range of
							nationally recognized clients and commercial partners, both public and private.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/benefits.png"
							alt="Benefits"
						/>
						<h4>Benefits</h4>
						<p>
							Our benefits are designed to add value and protection to your business operations,
							providing you with security, stability, and peace of mind in a world full of risks.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/financial.png"
							alt="Comprehensive financial protection"
						/>
						<h4>Comprehensive financial protection</h4>
						<p>
							Our reinsurance company offers solid and reliable coverage that protects your
							company&apos;s assets and financial resources against unforeseen losses.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/risk.png"
							alt="Risk reduction"
						/>
						<h4>Risk reduction</h4>
						<p>
							With our extensive experience and knowledge in risk management, we help minimize your
							company&apos;s exposure to adverse events, providing you with peace of mind and
							stability.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/access.png"
							alt="Access to additional capital"
						/>
						<h4>Access to additional capital</h4>
						<p>
							By working with us, you will have access to additional capital in the event of large
							claims, allowing you to manage and mitigate financial risks more effectively.
						</p>
					</Card>
					<Card>
						<img
							src="/img/partner/adaptability.png"
							alt="Adaptability"
						/>
						<h4>Adaptability</h4>
						<p>
							Our reinsurance solutions are tailored to your company&apos;s specific needs, offering
							flexible and customized options that fit your risk profile and business objectives.
						</p>
					</Card>
				</Grid>
			</div>
		</Content>
	);
};

export default Partner;
