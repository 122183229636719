import styled from 'styled-components';

export const Wrap = styled.header`
	width: 100%;
	position: fixed;
	z-index: 8888 !important;
	transition: all 0.7s ease;
	&.scrolled {
		background-color: #132643;
		box-shadow: 0px 2px 4px #00000014;
	}
`;

export const HeaderCont = styled.div`
	width: 100%;
	padding: 0 5%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 150px 1fr;
	align-items: center;
	column-gap: 10px;
	height: 105px;
`;

export const Fantasma = styled.div`
	width: 100%;
	height: 105px;

	@media only screen and (max-width: 768px) {
		height: 60px;
	}
`;
export const Logo = styled.div`
	display: flex;
	align-items: center;
	img {
		display: block;
		width: 100%;
		max-height: 68px;
		cursor: pointer;

		@media only screen and (max-width: 1199px) {
			max-height: 50px;
		}
	}
`;

export const NumberButton = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 70% 30%;
	align-items: center;
	text-align: center;
	order: 3;

	@media only screen and (max-width: 768px) {
		order: 2;
	}

	.buttonHeader {
		margin: 0 auto;
	}

	.iconPhone {
		margin-bottom: -4px;
	}
`;
