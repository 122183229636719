import styled from 'styled-components';

export const Card = styled.div`
	width: 100%;
	padding: 1rem 0 2rem 0;
	img {
		display: block;
		width: 100%;
		height: auto;
		max-width: 130px;
		margin: auto;
	}
	h3 {
		font-size: 1rem;
		margin: 0;
		padding: 0;
		font-weight: 500;
		text-align: center;
	}
`;
