import Routes from './Routes';

import { ThemeProvider } from 'styled-components';
import { LightTheme } from './styles/colors';
import 'animate.css';
function App() {
	return (
		<ThemeProvider theme={LightTheme}>
			<Routes />
		</ThemeProvider>
	);
}
export default App;
