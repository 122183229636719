import { DownArrow } from './styles';
const DownArrowIcon = () => {
	return (
		<DownArrow>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="25"
				viewBox="0 0 12 7">
				<path
					fill="none"
					stroke="red"
					strokeWidth="1.5"
					d="M1 1l5 5 5-5"></path>
			</svg>
		</DownArrow>
	);
};
export default DownArrowIcon;
