import styled from 'styled-components';

export const Card = styled.div`
	width: 100%;
	padding: 1rem;
`;

export const SeparatorText = styled.div`
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	margin: 3rem 0 !important;
`;
