import { Content, Paragraph, Grid, TexstTitle, Title } from 'common/styles';
import { Card, SeparatorText } from './styles';
import DownArrow from 'common/downArrow';
const OurCompany = () => {
	return (
		<Content
			background="#132643"
			id="ourcompany">
			<div>
				<DownArrow />
				<Title
					$center
					$margin="3rem 0 0 0"
					$color="#FF0000">
					Our company
				</Title>
				<Paragraph
					$color="#fff"
					$maxWidth="80%"
					$margin="3rem auto"
					$center>
					At Epsilon Insurance we are experts in reinsurance. We work to provide peace of mind and
					stability to your business in a world that is constantly changing.
				</Paragraph>
				<Grid $columns={2}>
					<Card className="cardSeparatorTwo">
						<TexstTitle $color="#FF0000">Mission</TexstTitle>
						<Paragraph $litteSize>
							To provide innovative reinsurance solutions that improve stability and mitigate risks
							in the insurance industry.
						</Paragraph>
					</Card>
					<Card className="cardSeparatorTwo">
						<TexstTitle $color="#FF0000">Vision</TexstTitle>
						<Paragraph $litteSize>
							To be the leading reinsurance company in Latin America.
						</Paragraph>
					</Card>
				</Grid>
				<SeparatorText>
					<Paragraph
						$color="#fff"
						$maxWidth="80%"
						$margin="3rem auto"
						$center>
						At Epsilon Insurance, we not only underwrite risks, but we also{' '}
						<span>build solid and lasting relationships</span> with our clients, based on trust,
						commitment, and exceptional results.
					</Paragraph>
				</SeparatorText>
				<Grid
					$columns={2}
					$separator="0 0 3rem 0">
					<Card className="cardSeparatorTwo">
						<TexstTitle $color="#FF0000">How do we do it?</TexstTitle>
						<Paragraph $litteSize>
							We use a comprehensive process that combines detailed analysis, evaluation of risks
							and a solid understanding of the market to develop reinsurance solutions adapted to
							the specific needs of each client.
						</Paragraph>
					</Card>
					<Card className="cardSeparatorTwo">
						<TexstTitle $color="#FF0000">What do we do?</TexstTitle>
						<Paragraph $litteSize>
							At Epsilon Insurance, we specialize in underwriting life risks, personal accidents and
							damage. Our experience and commitment allow us to offer reliable reinsurance solutions
							that are adapted to the needs of our clients.
						</Paragraph>
					</Card>
				</Grid>
			</div>
		</Content>
	);
};

export default OurCompany;
