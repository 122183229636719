import Hero from 'components/hero';
import Statement from 'components/statement';
import OurCompany from 'components/ourCompany';
import Highlights from 'components/highlights';
import Partner from 'components/partner';
const Home = () => {
	return (
		<>
			<Hero />
			<Statement />
			<OurCompany />
			<Highlights />
			<Partner />
		</>
	);
};
export default Home;
