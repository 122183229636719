import { useContext } from 'react';
import { Wrap } from './styles';
import { GlobalsContext } from '../../context/globals';
const Hamburger = () => {
	const { state, dispatch } = useContext(GlobalsContext);
	const openMenu = () => {
		dispatch({
			statusMenu: !state.statusMenu,
		});
	};
	return (
		<Wrap
			className={`hamburguer ${state.statusMenu ? 'open' : ''}`}
			onClick={openMenu}>
			<span></span>
			<span></span>
			<span></span>
		</Wrap>
	);
};
export default Hamburger;
