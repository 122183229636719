import styled from 'styled-components';

interface IGrid {
	$columns?: number;
	$margin?: string;
	disabled?: boolean;
	$separator?: string;
}
export const Grid = styled.div<IGrid>`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${({ $columns }) => ($columns ? $columns : '3')}, 1fr);
	grid-column-gap: 2.5%;
	grid-row-gap: 30px;
	margin-bottom: ${({ $margin }) => ($margin ? $margin : '0')};
	margin: ${({ $separator }) => ($separator ? $separator : '0')};
	${({ disabled }) =>
		disabled &&
		`
    position: relative;
    opacity: 0.5;
    pointer-events: none;
  `}
	${({ $columns }) => {
		if ($columns === 2) {
			return `
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
		if ($columns === 3) {
			return `
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
		if ($columns === 4) {
			return `
        @media screen and (max-width: 1024px) {
          grid-template-columns: repeat(3,1fr);
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
		if ($columns === 5) {
			return `
        @media screen and (max-width: 1060px) {
          grid-template-columns: repeat(4,1fr);
        }
        @media screen and (max-width: 890px) {
          grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 680px) {
          grid-template-columns: repeat(1,1fr);
        }
      `;
		}
	}}
  & .cardSeparatorFour {
		border-right: 1px solid #e5e5e5;
		&:last-child {
			border-right: none;
		}
		@media screen and (max-width: 1024px) {
			border-right: 1px solid #e5e5e5;
			&:last-child {
				border-right: none;
			}
			&:nth-child(3) {
				border-right: none;
			}
		}
		@media screen and (max-width: 768px) {
			border-right: 1px solid #e5e5e5;
			&:nth-child(even) {
				border-right: none;
			}
			&:nth-child(3) {
				border-right: 1px solid #e5e5e5;
			}
		}
		@media screen and (max-width: 480px) {
			border-bottom: 1px solid #e5e5e5;
			border-right: none !important;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	& .cardSeparatorTwo {
		border-right: 1px solid #e5e5e5;
		&:last-child {
			border-right: none;
		}
		@media screen and (max-width: 768px) {
			border-bottom: 1px solid #e5e5e5;
			border-right: none !important;
			&:last-child {
				border-bottom: none;
			}
		}
	}
`;
interface IFlex {
	$margin?: string;
	$h?: string;
	$v?: string;
	$gap?: string;
}
export const Flex = styled.div<IFlex>`
	display: flex;
	margin: ${props => (props.$margin ? props.$margin : '0')};
	justify-content: ${props => (props.$h ? props.$h : 'space-between')};
	align-items: ${props => (props.$v ? props.$v : 'strech')};
	gap: ${props => (props.$gap ? props.$gap : '0')};
	flex-wrap: wrap;
`;

interface MidbedProps {
	background?: string;
	color?: string;
	$padding?: string;
}
export const Content = styled.section<MidbedProps>`
	width: 100%;
	scroll-margin-top: 80px;
	background-color: ${props => props.background || '#f5f5f5'};
	color: ${props => props.color || '#fff'};
	padding: ${props => props.$padding || '2rem 5%'};
	& > div {
		max-width: 1260px;
		margin: 0 auto;
	}
`;
