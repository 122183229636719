import styled from 'styled-components';

interface ICardHighlight {
	$background: string;
}
export const CardHighlight = styled.div<ICardHighlight>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	min-height: 400px;
	background: url(${props => props.$background}) no-repeat center center;
	background-size: cover;
	text-align: center;
	color: #fff;
	font-size: 1.325rem;
	h4 {
		font-weight: 800;
		margin-bottom: 1rem;
	}
`;

export const NumberCard = styled.div`
	padding: 2rem;
	border-bottom: 3px solid #bc2227;
	b {
		display: block;
		font-size: 4rem;
	}
	p {
		text-transform: uppercase;
		font-size: 1.9rem;
	}
`;
