import styled from 'styled-components';

export const Wrap = styled.div`
	color: #fff;
	text-align: right;

	input {
		margin: 1rem;
		padding: 1rem 2rem;
		border: 1px solid #fff;
		background: transparent;
		color: #fff;
		border-radius: 15px;
		width: 100%;
	}
`;
