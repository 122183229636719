import styled from 'styled-components';

export const Wrap = styled.div`
	width: 100%;
`;

export const MenuItem = styled.div`
	display: block;
	position: relative;
	cursor: pointer;
	opacity: 0;
	height: 80px;
	display: flex;
	align-items: center;
	span {
		margin-right: 5px;
		font-weight: 900;
		color: red;
	}
	&.mainLink {
		@media only screen and (max-width: 768px) {
			width: 100%;
			border-bottom: 1px solid #1f4071;
			&.activeDrop {
				&:before {
					transform: rotate(135deg);
					margin-top: -6px;
					transition: 0.2s;
				}
			}
		}
		a,
		.notLinkItem {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.01px;
			color: #fff;
			position: relative;
		}
	}

	&.subMenuItem {
		height: auto;
		border-top: 1px solid #1f4071;
		padding: 15px 0 !important;
		a {
			&:before {
				content: unset !important;
			}
		}

		@media screen and (min-width: 769px) {
			max-width: 255px;
		}

		&:first-child {
			border-top: none;
			@media screen and (min-width: 769px) {
				padding: 0 0 15px !important;
			}
		}
		@media screen and (max-width: 768px) {
			&:first-child {
				border-top: 1px solid #1f4071;
				margin-top: 15px !important;
			}
		}

		&:last-child {
			padding: 15px 0 0 !important;
		}
	}
`;
export const Menu = styled.div`
	display: flex;
	gap: 2rem;
`;
export const SubMenuProducts = styled.div``;
export const SolutionsIndustry = styled.div`
	width: 410px !important;
	border: 1px solid #e4e9ef;
	position: absolute;
	right: -408px;
	opacity: 0;
	cursor: text !important;
	height: 100% !important;
	padding: 15px 0 15px 15px !important;
	background-color: #ffffff;
	a {
		&:before {
			content: unset !important;
		}
	}
	@media screen and (max-width: 768px) {
		display: none;
	}
`;
export const Nav = styled.nav`
	width: 100%;
	height: 80px;
	& > ${Menu} > ${MenuItem} {
		pointer-events: auto;
		opacity: 1;
	}
	& > ${Menu} {
		display: flex;
		justify-content: flex-end;
	}
	& ${MenuItem} > ${Menu} {
		position: absolute;
		pointer-events: none;
	}
	${MenuItem}:hover > ${Menu} > ${SubMenuProducts} > ${MenuItem},
	${MenuItem}:hover > ${Menu} > ${SolutionsIndustry} {
		opacity: 1;
	}
	${MenuItem}:hover > ${Menu} {
		pointer-events: initial;
		position: fixed;
		top: 105px;
		width: auto;
		background: #e4e9ef;
		box-shadow:
			rgba(0, 0, 0, 0.03) 0px 0.125rem 0.3rem -0.0625rem,
			rgba(0, 0, 0, 0.06) 0px 0.275rem 0.75rem -0.0625rem;
		@media screen and (max-width: 768px) {
			background: transparent;
			box-shadow: none;
		}
	}
	.services {
		padding: 20px 20px;
		display: grid;
		transform: translateX(-350px);
		grid-template-columns: repeat(1, 1fr);
		& > div {
			width: 100%;
			height: auto;
			margin: 0;
			padding: 0;
		}
		@media screen and (max-width: 1200px) {
			transform: translateX(-270px);
		}
		@media screen and (max-width: 992px) {
			transform: translateX(-350px);
		}
		@media screen and (max-width: 768px) {
			gap: 20px;
			transform: translateX(0);
		}
	}
	@media screen and (max-width: 768px) {
		display: none;
		&.open {
			width: 100%;
			position: absolute;
			left: 50%;
			top: 105px;
			padding: 20px;
			transform: translate(-50%, 0);
			display: flex;
			margin: auto;
			height: auto;
			& > ${Menu} {
				width: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: start;
				text-align: left;
				position: static;
			}
			& ${MenuItem}, ${SubMenuProducts} > ${MenuItem} {
				display: block;
				position: static;
				height: auto;
				padding: 15px 0;
				color: ${({ theme }) => theme.primaryColor};
				&:first-child {
					padding: 0 0 15px;
				}
				&:last-child {
					span {
						margin-bottom: 0;
					}
				}
				span {
					display: block;
					margin-bottom: 20px;
				}
			}
			& ${MenuItem} > ${Menu} {
				position: static;
				pointer-events: initial;
				opacity: 1;
				display: block;
				padding: 0;
			}
			& ${MenuItem} > ${Menu} > ${SubMenuProducts} > ${MenuItem} {
				position: static;
				pointer-events: initial;
				opacity: 1;
				display: block;
				width: auto;
				height: auto;
				background: transparent;
				a {
					text-align: center;
				}
			}
		}
	}
`;
export const Overlay = styled.div`
	display: none;
	@media screen and (max-width: 768px) {
		&.open {
			display: block;
			position: fixed;
			top: 105px;
			left: 0;
			width: 100%;
			// height: 100vh;
			height: 100%;
			background: #132643;
			backdrop-filter: blur(4px);
			z-index: -1 !important;
		}
	}
`;
export const Icons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
	margin-top: 0;
`;
