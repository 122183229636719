import { Wrap, Footer, Logo } from './styles';
import { Flex } from 'common/styles';
import Contact from 'common/contact';
const FooterComponent = () => {
	return (
		<Wrap id="contact">
			<Footer>
				<Flex>
					<Logo src="/img/logo_footer.png" />
					<Contact />
				</Flex>
			</Footer>
		</Wrap>
	);
};

export default FooterComponent;
