export const LightTheme = {
	name: 'light',
	background: '#FFF',
	body: '#f8f8f8',
	primaryColor: '#f47304',
	primaryHoverColor: '#cb5f10',
	primaryUnselectedColor: '#f78f6a',
	fontColor: '#3a3a3a',
	contentFontColor: '#878787',
	separatorColor: '#d7d7d7',
	menuLines: '#f3f3f3',
	success: '#97CECC',
};
export const DarkTheme = {
	name: 'dark',
	background: '#242322',
	body: '#1b1a19',
	primaryColor: '#f47304',
	primaryHoverColor: '#cb5f10',
	primaryUnselectedColor: '#f78f6a',
	fontColor: '#8f8f8f',
	contentFontColor: '#878787',
	separatorColor: '#424242',
	menuLines: '#313131',
	success: '#97CECC',
};
