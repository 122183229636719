import styled from 'styled-components';

export const Wrap = styled.div`
	display: none;
	margin-left: 0;
	@media screen and (max-width: 768px) {
		display: block;
		width: 25px;
		height: 20px;
		left: 33%;
		margin-top: 2px;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
		z-index: 9999;
		span {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			background: #ff0000;
			border-radius: 9px;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;
		}

		span:nth-child(1) {
			top: 0px;
			-webkit-transform-origin: left center;
			-moz-transform-origin: left center;
			-o-transform-origin: left center;
			transform-origin: left center;
		}

		span:nth-child(2) {
			top: 8px;
			-webkit-transform-origin: left center;
			-moz-transform-origin: left center;
			-o-transform-origin: left center;
			transform-origin: left center;
		}

		span:nth-child(3) {
			top: 16px;
			-webkit-transform-origin: left center;
			-moz-transform-origin: left center;
			-o-transform-origin: left center;
			transform-origin: left center;
		}

		&.open span:nth-child(1) {
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
			top: -1px;
			left: 9px;
		}

		&.open span:nth-child(2) {
			width: 0%;
			opacity: 0;
		}

		&.open span:nth-child(3) {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
			top: 16px;
			left: 8px;
		}
	}
`;
