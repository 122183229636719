import styled from 'styled-components';
export const DownArrow = styled.div`
	margin: 1rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	animation: arrow 2s infinite;
	@keyframes arrow {
		0% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(10px);
		}
		100% {
			transform: translateY(0);
		}
	}
	.arrow {
		width: 45px;
		height: 45px;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
	}
	.arrow::before {
		content: '';
		position: absolute;
		width: 45px;
		height: 45px;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(-90deg);
	}
`;
